import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { map, catchError, finalize } from 'rxjs/operators';
import { Subscription, Observable, throwError } from 'rxjs';
// import { UseraccessService } from '../../services/useraccess.service';
import * as constants from './app.constants';
import { LoaderService } from '../services/loader.service';
import { ToastService } from '../component/toaster/toast.service';
import { environment } from 'environments/environment';
import { LoginService } from '../services/login.service';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';
// import { ToastService } from '../modules/toaster/toast.service';

// import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  public openApis: any = constants.openApis;
  public pnqApis: any = constants.pnqApis;
  isTimer;

  constructor(private router: Router,
    public loaderService: LoaderService,private loginService:LoginService,

    private toast: ToastService
    // public toast: ToastService
  ) { }
  /**
   * intercept method will run before http call sent
   * @param req Http Request
   * @param next  HttpHandler , handles all the http requests
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /** Set auth token , common headers here */

    this.loaderService.isLoading.next(true);
    let openApiFlag = false;
    for (let api of this.openApis) {
      if (req.url.indexOf(api) !== -1) {
        openApiFlag = true;
        // //console.log('has data');
        break;
      }
    }

    //  Set auth token only if api is not open /
    if (!openApiFlag) {
      let token = localStorage.getItem('token');
      if (token) {
        const newRequest = req.clone({
          // set auth token to api here
          headers: req.headers.set('Authorization', token)
        });
        req = newRequest;
      }
    }

    // set auth token for pnq
    let pnqApiFlag = false;
    for (let api of this.pnqApis) {
      if (req.url.indexOf(api) !== -1) {
        pnqApiFlag = true;
        // //console.log('has data');
        break;
      }
    }

    if (pnqApiFlag) {
      let token = localStorage.getItem('pnqToken');
      if (token) {
        const newRequest = req.clone({
          // set auth token to api here
          headers: req.headers.set('Authorization', token)
        });
        req = newRequest;
      }
    }
   



    /**
     * you can show common loader here
     */

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
        // //console.log('intercepter event',event);
        return event;
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error) {
            if ('response_message' in error.error) {
              const { response_message } = error.error;
              if (response_message == "Failed to authenticate token.") {
              if(localStorage.getItem('token'))  {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                const refres_token=localStorage.getItem('refresh_token');
                if(refres_token){
                  this.loginService.refreshToken().subscribe((res:any)=>{
                 if(res.response_body){
               let  {token,user}=res.response_body;
                localStorage.clear();
                localStorage.setItem('token',token);
                localStorage.setItem('refresh_token',refres_token);
                localStorage.setItem('user',JSON.stringify(user));
                location.reload();
                }else{
                  localStorage.clear()
                  this.router.navigate(['login']);
                }
                },err=>{
                  localStorage.clear()
                  this.router.navigate(['login']);
                });
              }else{
                localStorage.clear();
                this.router.navigate(['login']);
              }

              }
                // if(localStorage.getItem('token')){this.toast.error(response_message);}
                 
                // localStorage.clear();
                //  this.router.navigate(['login']);
                // window.location.href=environment.marketing_app_url;
              }
            }
          }

          // let data = {
          //   reason: error && error.error.reason ? error.error.reason : '',
          //   status: error.status
          // };
          // // //console.log(data);

          // if (error.status === 401) {
          //     // redirect to the login route
          //     this.toast.error('Session Timeout!!.Please Login Again');
          //     localStorage.clear();
          //     this.router.navigate(['/login']);
          // }
        }
        return throwError(error);
      }),
      finalize(() => {
        clearTimeout(this.isTimer);
        this.isTimer = setTimeout(() => {
        this.loaderService.isLoading.next(false);
        }, 2000);
      })

    );

    // return next.handle(req).do(
    //     (event: HttpEvent<any>) => {
    //         if (event instanceof HttpResponse) {
    //             // do stuff with response if you want
    //         }
    //     },
    //     (err: any) => {
    //         if (err instanceof HttpErrorResponse) {
    //             if (err.status === 401) {
    //                 // redirect to the login route
    //                 // this.router.navigate(['/']);
    //             }
    //         }
    //     }
    // );
  }

  // *******************************************************************
}
