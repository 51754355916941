import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { faqListEndpoint } from '../commons/service-url';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public httpOption;
  constructor(private readonly http: HttpClient) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  index = <T>(): Observable<T> => this.http.get<T>(environment.baseUrl + faqListEndpoint)
}
