import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as userAction from '../actions/user.action';
import * as userModel from '../../shared/models/user.model';
import { rap$ } from 'app/shared/roles';


export interface State {

}

export const reducers: ActionReducerMap<State> = {

};

const initialCompanyStoreReportState: userModel.UserProfile = {
    privileges: [],
    token: null,
    user: null
}


export const repayReducer = createReducer(
    initialCompanyStoreReportState,
    on(userAction.SetUser, (state: userModel.UserProfile, { payload }) => {
        // rap$.setUser(payload);
        return { ...state, user: { ...state.user, ...payload } };
    }), 
    on(userAction.SetUserPrivilege, (state: userModel.UserProfile, { payload }) => {

        return { ...state, privileges: [...payload] };
    }),
    on(userAction.GetUserProfile, (state: userModel.UserProfile) => {
        return { ...state }
    }),
    on(userAction.UserProfileFailure, (state: userModel.UserProfile, { error }) => {
        return { ...state, error };
    })
);

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
