import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Service to show loader
 */
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class LoaderService {
  public isLoading = new BehaviorSubject<boolean>(false);
  public isLoadingObservable$ = this.isLoading.asObservable();
  public isBusy = new BehaviorSubject<boolean>(false);
  public isBusyObservable$ = this.isBusy.asObservable();

  constructor() { }
}

