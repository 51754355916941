import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFaqReducer } from '../../shared/models/faq.model';

const getFaqState = createFeatureSelector<IFaqReducer>('faq');

export const faqLoading = createSelector(getFaqState,
    (state: IFaqReducer) => state.loading);

export const faqList = createSelector(getFaqState,
    (state: IFaqReducer) => state.list);
