import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ɵBrowserDomAdapter } from '@angular/platform-browser';
import { CommonDataObservableService } from './commondata.service';
interface CommonResponse {

  response_code: number
  response_message: string;
  response_body: any;
}

@Injectable({
  providedIn: 'root'
})
export class VisualizationService {


  public httpOption;
  constructor(public http: HttpClient, private commonService: CommonDataObservableService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  //`?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`
  getTotalSales(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTotalSales + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getCampaignAnalyticsOrders(): Observable<CommonResponse> {
    console.log("******************************************************************=> 1");
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getCampaignAnalyticsOrders).pipe(catchError(this.handleError));
  }

  getTotalSalesNOrders(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTotalSalesNOrders + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getTotalOrders(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTotalOrders + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getTotalOrdersFiveStar(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTotalOrdersFiveStar + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  geEmailSchedulerFiveStar(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.geEmailSchedulerFiveStar + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  geEmailSentFiveStar(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.geEmailSentFiveStar + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getActiveSKu(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getActiveSKu + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getOutOfStockSku(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getOutOfStockSku + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getSalesPerformance(storeId: any, startDate: string, endDate: string,store_id=null,marketplace_id=null,country_code?): Observable<CommonResponse> {
    const store_filter=store_id&&store_id.length?store_id.map(e=>`&filter_store_id=${encodeURIComponent(e)}`).join(''):'';
    const integration_filter=marketplace_id&&marketplace_id.length?marketplace_id.map(e=>`&marketplace_id=${encodeURIComponent(e)}`).join(''):'';
    const country_filter=country_code&&country_code.length?country_code.map(e=>`&country_code=${encodeURIComponent(e)}`).join(''):'';
    const combined_filter=store_filter+integration_filter+country_filter;
    // const marketplace_store_filter=store_id&&marketplace_id?`&filter_store_id=${store_id}&marketplace_id=${marketplace_id}`:'';
   return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getSalesPerformance + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}${combined_filter}`).pipe(catchError(this.handleError));
  }

  getSalesMixByMarketplace(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getSalesMixByMarketplace + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getMarketplacePerformance(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getMarketplacePerformance + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getTooltipMessages(storeId: any): Observable<CommonResponse> {

    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTooltipMessages + `?store_id=${storeId}`+`&is_company=false`).pipe(catchError(this.handleError));
  }

  getSalesMix(storeId: any, startDate: string, endDate: string,store_id=null,marketplace_id=null,country_code?): Observable<CommonResponse> {
    const country_filter=country_code?`&country_code=${country_code}`:'';
    const marketplace_store_filter=store_id&&marketplace_id?`&filter_store_id=${store_id}&marketplace_id=${marketplace_id}`:'';
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getSalesMix + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}${marketplace_store_filter}${country_filter}`).pipe(catchError(this.handleError));
  }


  getMarketPlaceOverview(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getMarketPlaceOverview + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }


  getStoreSalesPerformance(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getBrandSalesPerformance + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }

  getOverdueOrders(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getOverdueOrders + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }


  getTopPerformingSku(storeId: any, startDate: string, endDate: string,store_id=null,marketplace_id=null): Observable<CommonResponse> {
    // let marketplace_store_filter=store_id&&marketplace_id?`&filter_store_id=${store_id}&marketplace_id=${marketplace_id}`:'';
    const store_filter=store_id&&store_id.length?store_id.map(e=>`&filter_store_id=${e}`).join(''):'';
    const common_filter=store_filter;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getTopPerformingSku + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}${common_filter}`).pipe(catchError(this.handleError));
  }

  getWorstPerformingSku(storeId: any, startDate: string, endDate: string,store_id=null,marketplace_id=null): Observable<CommonResponse> {
    let marketplace_store_filter=store_id&&marketplace_id?`&filter_store_id=${store_id}&marketplace_id=${marketplace_id}`:'';
    
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getWorstPerformingSku + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}${marketplace_store_filter}`).pipe(catchError(this.handleError));
  }


  getInventoryStatus(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventoryStatus + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getDispatchStatus(storeId: any, startDate: string, endDate: string,store_id=null,marketplace_id=null): Observable<CommonResponse> {
    let marketplace_store_filter=store_id&&marketplace_id?`&filter_store_id=${store_id}&marketplace_id=${marketplace_id}`:'';
    
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getDispatchStatus + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}${marketplace_store_filter}`).pipe(catchError(this.handleError));
  }

  getDispatchStatusByMarketplaceId(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getDispatchStatusByMarketplaceId + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getOverdueOrdersByMarketplace(storeId: any, startDate: string, endDate: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getOverdueOrdersByMarketplace + `?startDate=${startDate}&endDate=${endDate}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getForecastByStoreId(store_id: any): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.salesOrderForecast, { params: { store_id } }).pipe(catchError(this.handleError));
  }

  getDashboardData(storeId: any, startDate: string, endDate: string) {
    return forkJoin([]).pipe(map(() => ({})))
    return forkJoin([
      this.getTotalSales(storeId, startDate, endDate),
     // this.getTotalSalesNOrders(storeId, startDate, endDate),
      this.getTotalOrders(storeId, startDate, endDate),
      this.getActiveSKu(storeId, startDate, endDate),
      this.getOutOfStockSku(storeId, startDate, endDate),
      this.getSalesPerformance(storeId, startDate, endDate),
      this.getSalesMixByMarketplace(storeId, startDate, endDate),
      this.getSalesMix(storeId, startDate, endDate),
      this.getMarketPlaceOverview(storeId, startDate, endDate),
      this.getStoreSalesPerformance(storeId, startDate, endDate),
      this.getOverdueOrders(storeId, startDate, endDate),
      this.getTopPerformingSku(storeId, startDate, endDate),
      this.getWorstPerformingSku(storeId, startDate, endDate),
      this.getInventoryStatus(storeId, startDate, endDate),
      this.getForecastByStoreId(storeId),
      this.getCampaignAnalyticsOrders()
    ]).pipe(
      map((data: any[]) => {
        const res = {
          data1: data[0],
          data2: data[1]
        };
        return data;
      }),
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    let fError;
    if (error) {
      if (error.error) {
        fError = error.error;
      }
    } else {
      fError = {
        error: {}
      }
    }
    // return an observable with a user-facing error message
    return throwError({ message: 'Something bad happened. Please try again later.', error: fError });
  }



  refereshStoreData() {
    this.commonService.responseData({
      type: 'store'
    })
  }
}
