import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { rap$ } from '../roles';


@Injectable()
export class ViewUsersListGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return rap$.viewUsersList;
  }
}

@Injectable()
export class ViewRolesListGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return rap$.viewRoles;
  }
}

@Injectable()
export class ViewSingleStoreGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return rap$.viewSingleStore;
  }
}

@Injectable()
export class ViewBillingDetailGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return rap$.viewBillingDetails;
  }
}

