import { Message } from './toast.model';
import { Injectable } from '@angular/core';
import { Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
      private message = new Subject<Message>();
      messageObservable$ = this.message.asObservable();

      private messageCommand = new Subject<any>();
      messageCommandObservable$ = this.messageCommand.asObservable();
      constructor() { }
      
      sendCommand(command: string){
        this.messageCommand.next(command)
      }
      sendMessage(content, style): void {
        const tmpMessage = new Message(content, style);
          this.message.next(tmpMessage);
      }

      success(content,message='', style= 'success',): void {
        const tmpMessage = new Message(content, style,message);
          this.message.next(tmpMessage);
      }

      error(content, errorMessage = '', style= 'danger'): void {
        //console.log("error message")
        const tmpMessage = new Message(content, style, errorMessage);
          this.message.next(tmpMessage);
      }

      info(content, style= 'info'): void {
        const tmpMessage = new Message(content, style);
          this.message.next(tmpMessage);
      }
      warn(content, style= 'warning'): void {
        const tmpMessage = new Message(content, style);
          this.message.next(tmpMessage);
      }
}

