import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ɵBrowserDomAdapter } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonDataObservableService } from './commondata.service'
// import { Contact } from 'app/component/contact/contact.component';
import { PaymentIntent } from '@stripe/stripe-js';
// import { BrowserDomAdapter } from 'angular2/platform/browser';
export class Contact {
  first_name: string;
  last_name: string;
  email:string;
  subject: string;
  organisation: string;
  country: string;
  message:string
}
interface User {
  email: string;
  password: string
}
export interface Account {
  user_id: number;
  first_name: string;
  last_name: string;
  phone_number: number;
  photo: string;
}
interface Password {
  password: string;
}

interface CommonResponse {

  response_code: number
  response_message: string;
  response_body: any;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public httpOption;
  constructor(public http: HttpClient, private router: Router, private commonDataService: CommonDataObservableService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };


  }


  // authorization code and tokens
  authorizationCode: string;
  oAuthToken: string;
  oAuthVerifier: string;

  private handleError(errorRespose: HttpErrorResponse) {
    if (errorRespose.error instanceof ErrorEvent) {
      return throwError(errorRespose.error);
    } else {
      if (errorRespose.hasOwnProperty('error')) {
        return throwError(errorRespose.error);
      } else {
        return throwError({
          message: 'Internal Server Error'
        });
      }
    }
  }



  login(data: User): Observable<CommonResponse> {
    const { email, password } = data;
    const body = {
      email, password
    }
    return this
      .http
      .post<CommonResponse>(environment.baseUrl + serviveUrl.userLogin, body).pipe(catchError(this.handleError));
  }

  register(data: User): Observable<CommonResponse> {
    const { email, password } = data;
    const body = {
      email, password
    }
    return this
      .http
      .post<CommonResponse>(environment.baseUrl + serviveUrl.userRegistration, body).pipe(catchError(this.handleError));
  }

  contact(data: Contact): Observable<CommonResponse> {
    return this
      .http
      .post<CommonResponse>(environment.baseUrl + serviveUrl.contact, data).pipe(catchError(this.handleError));
  }

  getAccountDetailsByUserId(userId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getAccountDetailsByUserId + userId).pipe(catchError(this.handleError));
  }

  updateAccountDetailsByUserId(data: any): Observable<CommonResponse> {
    
    return this.http.post<CommonResponse>(environment.baseUrl + serviveUrl.updateAccountDetailsByUserId, data).pipe(catchError(this.handleError));
  }

  verifyResetToken(token: string): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.verifyResetPasswordToken + token).pipe(catchError(this.handleError));
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.forgetPassword + email).pipe(catchError(this.handleError));
  }
  updatePassword(token: string, password: string): Observable<any> {
    const body = {
      password
    }
    return this.http.post(environment.baseUrl + serviveUrl.updatePassword + token, body).pipe(catchError(this.handleError));
  }
  getCountry(): Observable<any> {

    return this.http.get<any>(environment.baseUrl + serviveUrl.CountryList).pipe(catchError(this.handleError));

  }



  getState(country: string): Observable<any> {

    return this.http.get<any>(environment.baseUrl + serviveUrl.statesList + country).pipe(catchError(this.handleError));

  }



  getPaymentIntent(amount: number): Observable<PaymentIntent> {

    return this.http.post<PaymentIntent>(environment.baseUrl + serviveUrl.createPaymentIntent, { amount }).pipe(catchError(this.handleError));

  }



  postPayment(payload: any): Observable<any> {

    return this.http.post<any>(environment.baseUrl + serviveUrl.postPayment, { data: payload }).pipe(catchError(this.handleError));

  }

  servicesWithPrivileges(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.servicesWithPrivileges).pipe(catchError(this.handleError));
  }

  getServicesPrivileges(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + serviveUrl.getServicesPrivileges).pipe(catchError(this.handleError));
  }
  getRoleDetails(role_id: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl + serviveUrl.getRoleDetails}`, { params: { role_id }}).pipe(catchError(this.handleError));
  }
  getPaymentsList(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl + serviveUrl.getPaymentsList}`).pipe(catchError(this.handleError));
  }
}
