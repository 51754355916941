import * as serviceUrls from './service-url';

export const openApis = [
  serviceUrls.userRegistration,
  serviceUrls.userLogin,
  serviceUrls.userActivateToken,
  serviceUrls.verifyResetPasswordToken,
  serviceUrls.forgetPassword,
  serviceUrls.updatePassword,
  serviceUrls.googleLogin,
  serviceUrls.googleRegistration,
];



export const bufferToImage = (bufferObj:{data:Array<any>,type:string}) => {

  let typAr = new Uint8Array(bufferObj.data);
  let imageUrl = typAr.reduce((data, byte)=> {
    return data + String.fromCharCode(byte);
    }, '');
    return imageUrl;
}

export const pnqApis=[
  serviceUrls.getAllTools,
  serviceUrls.getAllInstances,
  serviceUrls.getSettingByTool,
  serviceUrls.testPriceMatrix,
  serviceUrls.getInstancesById,
  serviceUrls.getkeysByInstancesId,
  serviceUrls.getAllDomains,
  serviceUrls.getAllMarkets,
  serviceUrls.uploadAndImportAsins,
  serviceUrls.getAllImportLog,
  serviceUrls.downloadFile,
  serviceUrls.getProcessLog,
  serviceUrls.addKey,
  serviceUrls.deleteKey,
  serviceUrls.updateKey,
  serviceUrls.updateSettings,
  serviceUrls.updateSettingsMultiple,
  serviceUrls.getAllActivityLog,
  serviceUrls.getAllActivityNames,
  serviceUrls.getDecryptKey
];


