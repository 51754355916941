import { createAction, props } from '@ngrx/store';
import * as companyStoreReporModel from 'app/shared/models/report.model';


const PREFIX = `[Xtreme] [CompanyStoreReport]`;

const SELECT_STORE_DATERANGE = `${PREFIX} Select Date Range`;

const API_GET_TOTAL_SALES = `${PREFIX} Api Get TotalSales`;
const API_GET_TOTAL_ORDERS = `${PREFIX} Api Get TotalOrders`;
const API_GET_TOTAL_ORDERS_FIVE_STAR = `${PREFIX} Api Get TotalOrdersFiveStar`;
const API_GET_ACTIVE_SKU = `${PREFIX} Api Get ActiveSKu`;
const API_GET_OUT_OF_STOCK_SKU = `${PREFIX} Api Get OutOfStockSku`;
const API_GET_NEW_SKU = `${PREFIX} Api Get NewSku`;
const API_GET_SALES_PERFORMANCE = `${PREFIX} Api Get SalesPerformance`;
const API_GET_SALES_MIX = `${PREFIX} Api Get SalesMix`;
const API_GET_SALES_MIX_BY_MARKETPALCe = `${PREFIX} Api Get SalesMix NBy marketplace`;
const API_GET_TOOTIP_MESSAGES = `${PREFIX} Api Get Tooltip Messages`;
const API_GET_MARKETPLACE_OVERVIEW = `${PREFIX} Api Get MarketPlaceOverview`;
const API_GET_MARKETPLACE_PERFORMANCE = `${PREFIX} Api Get MarketPlacePErformance`;
const API_GET_STORE_SALES_PERFOMANCE = `${PREFIX} Api Get StoreSalesPerformance`;
const API_GET_OVERDUE_ORDERS = `${PREFIX} Api Get OverdueOrders`;
const API_GET_OVERDUE_ORDERS_BY_MARKETPLACE = `${PREFIX} Api Get OverdueOrders By Marketplace`;
const API_GET_TOP_PERFOMING_SKU = `${PREFIX} Api Get TopPerformingSku`;
const API_GET_WORST_PERFOMING_SKU = `${PREFIX} Api Get WorstPerformingSku`;
const API_GET_INVENTORY_STATUS = `${PREFIX} Api Get InventoryStatus`;
const API_GET_DISPATCH_STATUS = `${PREFIX} Api Get DispatchStatus`;
const API_GET_DISPATCH_BY_MARKETPLACE_STATUS = `${PREFIX} Api Get Dispatch Status By Marketplace`;
const SALES_FORECAST_FOR_STORE = `${PREFIX} Api Get Dispatch Status By Sale Forecast`;

const LOAD_STORE_DATA = `${PREFIX} Api Get Store Report Data`;
const LOAD_ALSTORE_DATA = `${PREFIX} Api Get All Store Report Data`;
const LOAD_STORE_DATA_SUCCESS = `${PREFIX} Api Get Store Report Success`
const LOAD_STORE_DATA_ERROR = `${PREFIX} Api Get Store Report Error`;
const LOAD_SALES_GROWTH = `${PREFIX} Load sales growth`
const API_GET_USER_STORES = `${PREFIX} Api Get User Stores`;

const API_SET_USER_STORES = `${PREFIX} Api Set User Stores`

const UPDATE_USER_STORE = `${PREFIX} Update User Store`;

const SELECT_STORE = `${PREFIX} Select Store`;
const API_SET_SALES_GROWTH = `${PREFIX} Api Set sales growth`
// Dashboard


export const SelectStoreDateRange = createAction(
  SELECT_STORE_DATERANGE, props<{ payload: companyStoreReporModel.StoreRange }>()
);
export const SelectStore = createAction(
  SELECT_STORE, props<{ payload: string | number }>()
);

export const UpdateUserStore = createAction(
  UPDATE_USER_STORE, props<{ payload: companyStoreReporModel.CompanyStore }>()
);

export const ApiGetTotalSales = createAction(
  API_GET_TOTAL_SALES
);
export const ApiGetTotalOrders = createAction(
  API_GET_TOTAL_ORDERS
);
// FiveStar
export const ApiGetTotalOrdersFiveStar = createAction(
  API_GET_TOTAL_ORDERS_FIVE_STAR
);

export const ApiGetActiveSKu = createAction(
  API_GET_ACTIVE_SKU
);

export const ApiSaleForecast = createAction(
  SALES_FORECAST_FOR_STORE
);

export const ApiGetOutOfStuckSku = createAction(
  API_GET_OUT_OF_STOCK_SKU
);
export const ApiGetNewSku = createAction(
  API_GET_NEW_SKU
);

export const ApiGetSalesPerformance = createAction(
  API_GET_SALES_PERFORMANCE
);

export const ApiGetSalesMix = createAction(
  API_GET_SALES_MIX
);
export const ApiGetSalesMixByMarketPlace = createAction(
  API_GET_SALES_MIX_BY_MARKETPALCe
);

export const ApiGetMarketPlaceOverview = createAction(
  API_GET_MARKETPLACE_OVERVIEW
);
export const ApiGetMarketPlacePerformance = createAction(
  API_GET_MARKETPLACE_PERFORMANCE
);

export const ApiGetTooltipMessages = createAction(
  API_GET_TOOTIP_MESSAGES
);


export const ApiGetStoreSalesPerformance = createAction(
  API_GET_STORE_SALES_PERFOMANCE
);

export const ApiGetOverdueOrders = createAction(
  API_GET_OVERDUE_ORDERS
);
export const ApiGetOverdueOrdersByMarketplace = createAction(
  API_GET_OVERDUE_ORDERS_BY_MARKETPLACE
);

export const ApiGetTopPerformingSku = createAction(
  API_GET_TOP_PERFOMING_SKU
);
export const ApiGetWorstPerformingSku = createAction(
  API_GET_WORST_PERFOMING_SKU
);

export const ApiGetInventoryStatus = createAction(
  API_GET_INVENTORY_STATUS
);

export const ApiGetDispatchStatus = createAction(
  API_GET_DISPATCH_STATUS
);

export const ApiGetDispatchByMarketPlaceStatus = createAction(
  API_GET_DISPATCH_BY_MARKETPLACE_STATUS
);





export const ApiGetUserStore = createAction(
  API_GET_USER_STORES
);
export const ApiSetUserStore = createAction(
  API_SET_USER_STORES,
  props<{ payload: Array<companyStoreReporModel.CompanyStore> }>()
);

export const ApiSetSalesGrowth = createAction(
  API_SET_SALES_GROWTH,
  props<{ payload: any }>()
);

export const LoadSalesGrowth = createAction(
  LOAD_SALES_GROWTH,
  props<{ payload: any }>()
);
export const loadCompanyAllStoreReport = createAction(
  LOAD_ALSTORE_DATA,
  props<{ payload: companyStoreReporModel.CompanyFilter }>()
);

export const loadCompanyStoreReport = createAction(
  LOAD_STORE_DATA,
  props<{ payload: companyStoreReporModel.store_report }>()
);

export const loadCompanyStoreReportSuccess = createAction(
  LOAD_STORE_DATA_SUCCESS,
  props<companyStoreReporModel.CompanyStoreReport>()
);

export const loadCompanyStoreReportFailure = createAction(
  LOAD_STORE_DATA_ERROR,
  props<{ error: any }>()
);
