import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  createSelector,
  MetaReducer,
  on
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as companyStoreReportAction from 'app/store/actions';
import * as companyStoreReporModel from 'app/shared/models/report.model';
import * as util from 'app/shared/utils/date.util';

export interface State {

}

export const reducers: ActionReducerMap<State> = {

};




const initialCompanyStoreReportState: companyStoreReporModel.CompanyStoreReportState = {
  currentStore: {
    storeId: null,
  },
  currentStoreRange: {
    storeId: null,
    startDate: util.getCurrentDay(),
    endDate: util.getCurrentDay(),
  },
  sales_growth:[],
  companyStores: [],
  companyStoreReports: [],
  error: null,
  isStoreLoaded: false
}


export const companyReducer = createReducer(
  initialCompanyStoreReportState,
  on(companyStoreReportAction.ApiSetSalesGrowth, (state: companyStoreReporModel.CompanyStoreReportState, { payload
  }) => {
    

    return { ...state, sales_growth:[...state.sales_growth,payload]};
  }),
  on(companyStoreReportAction.ApiSetUserStore, (state: companyStoreReporModel.CompanyStoreReportState, { payload
  }) => {

    const company = payload.filter((store) => store.is_company == 1)[0];
    let isStoreLoaded = false;
    if(payload && payload.length>0){
      isStoreLoaded = true;
    }

    return { ...state, companyStores: [...payload], currentStore: { storeId: company?.id } ,isStoreLoaded};
  }),
  on(companyStoreReportAction.UpdateUserStore, (state: companyStoreReporModel.CompanyStoreReportState, { payload
  }) => {
    const { id } = payload;
    const companyStores = state.companyStores.map((companyStore) => {
      if (companyStore.id == id) {
        return {
          ...companyStore,
          ...payload
        }
      }
      return companyStore;
    })
    return { ...state, companyStores: [...companyStores] };
  }),


  on(companyStoreReportAction.SelectStoreDateRange, (state: companyStoreReporModel.CompanyStoreReportState, payload) => {
    return { ...state, currentStoreRange: { ...state.currentStoreRange, ...payload } };
  }),
  on(companyStoreReportAction.SelectStore, (state: companyStoreReporModel.CompanyStoreReportState, { payload }) => {
    return { ...state, currentStore: { storeId: payload } };
  }),
  on(companyStoreReportAction.loadCompanyStoreReportSuccess, (state: companyStoreReporModel.CompanyStoreReportState, data) => {
    const { report, storeId, dateRange,filter_store_id,marketplace_id,country_code } = data;
    if (!storeId || !report) {
      return state;
    }
    // console.log("dateRange", dateRange)
    let companyStoreReports = [];
    let foundAtIndex = -1;
    companyStoreReports = (state.companyStoreReports || []).map((companyStore, index) => {
      if (storeId == companyStore.storeId) {
        foundAtIndex = index;
        
        return {
          ...companyStore,
          report: {
            ...companyStore.report,
            ...report
          },
          filter_store_id:filter_store_id||companyStore.filter_store_id,
          marketplace_id:marketplace_id||companyStore.marketplace_id,
          country_code:country_code||companyStore.country_code,
          dateRange: {
            ...companyStore.dateRange,
            ...(dateRange || {})
          }
        }
      }
      return companyStore;
    })
    if (foundAtIndex == -1) {
      companyStoreReports.push({ report, storeId,filter_store_id, marketplace_id,country_code,dateRange });
    }

    return { ...state, companyStoreReports: [...companyStoreReports], };
  }),



  on(companyStoreReportAction.loadCompanyStoreReportFailure, (state: companyStoreReporModel.CompanyStoreReportState, { error }) => {
    return { ...state, error };
  })
);



export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
