// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   baseUrl : 'https://dev.x.bosontech.ai',
  //baseUrl:'http://localhost:2501',
   API_URL:'https://dev.x.bosontech.ai/api/v1/ppc/',
 //API_URL:'http://localhost:2501/api/v1/ppc/',
  //repaybaseUrl:'http://localhost:8000'
  repaybaseUrl:'https://dev.x.bosontech.ai',
  PNQ_API:'https://xpnqbackend.bosontech.ai',
  marketing_app_url:'https://xtreme.sceneindia.com',
 mixpanel_project_token:'b3271e8c0fc72d72faaa097ad99f223f',
  mixpanel_api_token:'8a37f15b2d25057d9ea2eb708c66e437',
scrapperAPIUrls_app: 'https://s.pullman.extreme.app/',
scrapperAPIUrls_loadbalancer: 'https://s.pullman.extreme.app/usa1',
scrapperAPIUrls_UUIDloadbalancer: 'https://s.pullman.extreme.app/load',
productDataAPIUrl:'https://dev.x.bosontech.ai'


};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
