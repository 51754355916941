import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoggedInGuard } from './shared/auth/auth-guard.service';
import { PreloadAllModules } from '@angular/router';

// import { PartialLayoutComponent } from './layout/partial-layout/partial-layout.component';

import { PARTIAL_ROUTES } from './shared/routes/partial-layout.routes';

/**
 * @author - anandkumar189
 * Define routes ,
 * Full Layout contains inside pages after login pages
 * Partial Layout contains Outside pages before login
 */
const appRoutes: Routes = [
  {
    path: 'home',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
  {
    path: '',
    loadChildren: () => import('./layout/full-layout/full-layout.module').then(m => m.FullLayoutModule)
    ,canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./layout/authentication-layout/authentication-layout.module').then(m => m.AuthenticationLayoutModule),canActivate:[LoggedInGuard]
   
  },
   {
    path: '**',
    loadChildren: () => import('app/component/error/error.module').then(m => m.ErrorModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
