import * as moment from 'moment';


export const getCurrentDay = () => {
    return moment().format('YYYY-MM-DD');
}
// const getDateRange = (type: DateType): DateRange => {
//     switch (type) {
//         case 'today':
//             return {
//                 startDate: moment().format('YYYY-MM-DD'),
//                 endDate: moment().format('YYYY-MM-DD'),
//                 dateType: 'today'
//             }
//         case '7days':
//             return {
//                 startDate: moment().format('YYYY-MM-DD'),
//                 endDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
//                 dateType: '7days'
//             }
//         case '30days':
//             return {
//                 startDate: moment().format('YYYY-MM-DD'),
//                 endDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
//                 dateType: '30days'
//             }
//         case '1yr':
//             return {
//                 startDate: moment().format('YYYY-MM-DD'),
//                 endDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
//                 dateType: '1yr'
//             }
//     }

// }