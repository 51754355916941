import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as userModel from '../../shared/models/user.model';

const getCompanyState = createFeatureSelector<userModel.UserProfile>('user');

const getUserProfile = createSelector(getCompanyState,
    (state: userModel.UserProfile) => state);

const getUser = createSelector(getUserProfile,
    (state: userModel.UserProfile): userModel.User => state.user);

const getUserPrivileges = createSelector(getCompanyState,
    (state: userModel.UserProfile):
        Array<userModel.Privilege> => state.privileges);
const getUserToken = createSelector(getCompanyState,
    (state: userModel.UserProfile) => state.token);

export {
    getUserProfile, getUser,
    getUserPrivileges, getUserToken,
}
