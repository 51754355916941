import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as companyStoreReporModel from 'app/shared/models/report.model';
import { getCurrencySymbol } from '@angular/common';
import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
export * from './faq.selector'

const getCompanyState = createFeatureSelector<companyStoreReporModel.CompanyStoreReportState>('company');

const getCurrentStoreId = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState) => state.currentStore.storeId);

const getCurrentStoreError = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState) => state.error);

const getCurrentStoreDateRange = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        companyStoreReporModel.DateRange => state.currentStoreRange);
const getCurrentStore = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        companyStoreReporModel.CurrentStore => state.currentStore);
const getCompanyStores = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        Array<companyStoreReporModel.CompanyStore> => state.companyStores);

const getStoreReports = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        Array<companyStoreReporModel.CompanyStoreReport> => state.companyStoreReports);
const getCompany = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        companyStoreReporModel.CompanyStore => {
        return state.companyStores.filter((companyStore) => companyStore.is_company == 1)[0];
    }
);
const getSalesGrowth=createSelector(getCompanyState,getCurrentStore,(state:companyStoreReporModel.CompanyStoreReportState,currentStore:companyStoreReporModel.CurrentStore)=>{
    return state.sales_growth.find(e=>e.store_id==currentStore.storeId);
})

const getSelectedStoreReport = createSelector(
    getCurrentStore,
    getStoreReports,
    (currentStore: companyStoreReporModel.CurrentStore, companyStoreReports: Array<companyStoreReporModel.CompanyStoreReport>):
        companyStoreReporModel.CompanyStoreReport => {
        const companyStoreReport = companyStoreReports.filter((companyStoreReport) => currentStore.storeId == companyStoreReport.storeId);

        if (companyStoreReport && companyStoreReport.length > 0) {
            return companyStoreReport[0];
        }
        return null;
    });
const hasBrand = createSelector(getCompanyState,
    (state: companyStoreReporModel.CompanyStoreReportState):
        boolean => {
        return state.companyStores.filter((companyStore) => companyStore.is_company == 0).length > 0
    }

);
const isStoreLoaded = createSelector(getCompanyState,hasBrand,
    (state: companyStoreReporModel.CompanyStoreReportState , hasBrand: boolean):
        boolean => {
        return state.isStoreLoaded;
    }
);
const getCurrentCompanyStore = createSelector(getCompanyState, getCurrentStoreId,
    (state: companyStoreReporModel.CompanyStoreReportState, storeId: string | number):
        companyStoreReporModel.CompanyStore => {
        return state.companyStores.filter((companyStore) => companyStore.id == storeId)[0];
    });
const getCurrentCompanyStoreConfig = createSelector(getCurrentStoreDateRange, getCurrentStoreId,
    (storeRange: companyStoreReporModel.StoreRange, storeId: string | number):
        companyStoreReporModel.StoreRange => {
        return {
            ...storeRange,
            storeId
        };
    });

const getSelectedStore = createSelector(
    getCurrentCompanyStoreConfig,
    getCompanyStores,
    (storeRange: companyStoreReporModel.StoreRange, companyStores: Array<companyStoreReporModel.CompanyStore>):
        companyStoreReporModel.CompanyStore => {
        const companyStore = companyStores.filter((companyStore) => storeRange.storeId == companyStore.id);

        if (companyStore && companyStore.length > 0) {
            return companyStore[0];
        }
        return null;
    });

const getDashSalesPerformance = createSelector(
    getSelectedStoreReport,

    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.AxisData> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.salesPerformance
        }
        return null;
    });

// const  getForecastData = createSelector(
//     getSelectedStoreReport,
//     ()
// )

const getDashSalesMix = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: any):
        Array<companyStoreReporModel.SalesMix> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.salesMix;
        }
        return null;
    });
const getDashMarketPlaceOVerview = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.MarketPlaceOverview> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.marketPlaceOverview
        }
        return null;
    });

const getTooltipMessages = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.TooltipMessages> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.tooltipMessages
        }
        return null;
    });

const getDashTotalSales = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        companyStoreReporModel.TotalSales => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.totalSales
        }
        return null;
    });

const getDashTotalOrders = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        companyStoreReporModel.TotalOrders => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.totalOrders
        }
        return null;
    });
    const getDashTotalOrdersFiveStar = createSelector(
        getSelectedStoreReport,
        (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
            companyStoreReporModel.TotalOrdersFiveStar => {
            if (currentStoreReport && currentStoreReport.report) {
                return currentStoreReport.report.totalOrdersFive
            }
            return null;
        });
        const getDashTotalEmailFiveStar = createSelector(
            getSelectedStoreReport,
            (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
                companyStoreReporModel.TotalEmailFiveStar => {
                if (currentStoreReport && currentStoreReport.report) {
                    return currentStoreReport.report.totalEmailFive
                }
                return null;
            });
            const getDashTotalEmailSentFiveStar = createSelector(
                getSelectedStoreReport,
                (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
                    companyStoreReporModel.TotalEmailSentFiveStar => {
                    if (currentStoreReport && currentStoreReport.report) {
                        return currentStoreReport.report.totalEmailSentFive
                    }
                    return null;
                });

const getDashTotalActiveSku = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        companyStoreReporModel.ActiveSku => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.activeSku
        }
        return null;
    });
const getDashTotalNewSku = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        companyStoreReporModel.NewSku => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.newSku
        }
        return null;
    });

const getDashTotalOutOfOrderSku = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        companyStoreReporModel.OutOfStockSku => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.outOfStockSku
        }
        return null;
    });
type StatsChange = 'nochange' | 'gain' | 'loss' | 'new';

const getDashKPI = createSelector(
    getDashTotalSales,
    getDashTotalOrders,
    getDashTotalOutOfOrderSku,
    getDashTotalActiveSku,
    (totalSales: companyStoreReporModel.TotalSales, totalOrders: companyStoreReporModel.TotalOrders,
        outOfStockSku: companyStoreReporModel.OutOfStockSku, activeSku: companyStoreReporModel.ActiveSku,
    ):
        Array<{
            iconUrl: string;
            value: string,
            formatter?: string,
            changeType: StatsChange,
            changePercentValue: string,
            header: string,
            subheader: string,
            action?: string
        }> => {


        let data: Array<{
            // iconUrl: string;
            // value: string,
            // formatter?: string,
            // changeType: StatsChange,
            // changePercentValue: string,
            // header: string,
            // subheader: string,
            // action?: string
            iconUrl: string;
            value: string,
            formatter?: string,
            changeType: StatsChange,
            changePercentValue: string,
            header: string,
            subheader: string,
            action?: string
        }> = [];
        if (totalSales) {
            const { growth, growthValue } = getGainValue(totalSales.growthValue, totalSales.growth)
            // new CurrencyPipe('en','USD').transform(parseInt((totalSales.totalSales || 0) + ''), 'USD','symbol-narrow','2.1-1'
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/reducedlogisticcost.png',
                value: getCurrencySymbol('USD','narrow' )+nFormatter(parseInt((totalSales.totalSales || 0) + ''), 1),
                changeType: growth,
                changePercentValue: growthValue,
                header: 'Total Sales',
                subheader: 'Total sales for the day',
            });
        } else {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/reducedlogisticcost.png',
                value: getCurrencySymbol('USD','narrow' )+nFormatter(parseInt((0) + ''), 1),
                changeType: 'nochange',
                changePercentValue: null,
                header: 'Total Sales',
                subheader: 'Total sales for the day',
            });
        }
        if (totalOrders) {
            const { growth, growthValue } = getGainValue(totalOrders.growthValue, totalOrders.growth)
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/orders.png',
                value: nFormatter(parseInt((totalOrders.totalOrders || 0) + ''), 1),
                changeType: growth,
                changePercentValue: growthValue,
                header: 'Total Orders',
                subheader: 'Total orders for the day',
            });
        } else {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/orders.png',
                value: nFormatter(parseInt(( 0) + ''), 1),
                changeType: 'nochange',
                changePercentValue: null,
                header: 'Total Orders',
                subheader: 'Total orders for the day',
            });
        }
        if (activeSku) {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/new.png',
                value: nFormatter(parseInt((activeSku.totalInventory || 0) + ''), 1),
                changeType: 'new',
                changePercentValue:nFormatter(parseInt((activeSku.growthValue || 0) + ''), 1),
                header: 'Active SKUs',
                subheader: 'Total Active SKUs ',
            });
        } else {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/new.png',
                value: nFormatter(parseInt(( 0) + ''), 1),
                changeType:'nochange',
                changePercentValue:null,
                header: 'Active SKUs',
                subheader: 'Total Active SKUs ',
            });
        }

        if (outOfStockSku) {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/outsku.png',
                value: nFormatter(parseInt((outOfStockSku.totalInventory || 0) + ''), 1),
                changeType:  'new',
                changePercentValue:  nFormatter(parseInt((outOfStockSku.growthValue || 0) + ''), 1),
                header: 'Out Of Stock SKU',
                subheader: 'Total Active SKUs ',
            });

        } else {
            data.push({
                iconUrl: 'assets/icons/dashboard/kpis/outsku.png',
                value: nFormatter(parseInt((0) + ''), 1),
                changeType:'nochange',
                changePercentValue:null,
                header: 'Out Of Stock SKU',
                subheader: 'Total Active SKUs ',
            });
        };
        return data;
    });
// Fivestar
    const getDashKPIFiveStar = createSelector(
        
        getDashTotalOrdersFiveStar,
        getDashTotalEmailFiveStar,
        getDashTotalEmailSentFiveStar,
        ( totalOrdersFive: companyStoreReporModel.TotalOrdersFiveStar,
            totalEmailFive: companyStoreReporModel.TotalEmailFiveStar, totalEmailSentFive: companyStoreReporModel.TotalEmailSentFiveStar,
        ):
            Array<{
                iconUrl: string;
                value: string,
                formatter?: string,
                changeType: StatsChange,
                changePercentValue: string,
                header: string,
                subheader: string,
                action?: string
            }> => {
    
    
            let data: Array<{
                // iconUrl: string;
                // value: string,
                // formatter?: string,
                // changeType: StatsChange,
                // changePercentValue: string,
                // header: string,
                // subheader: string,
                // action?: string
                iconUrl: string;
                value: string,
                formatter?: string,
                changeType: StatsChange,
                changePercentValue: string,
                header: string,
                subheader: string,
                action?: string
            }> = [];
         
            if (totalOrdersFive) {
                const { growth, growthValue } = getGainValue(totalOrdersFive.growthValue, totalOrdersFive.growth)
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/orders.png',
                    value: nFormatter(parseInt((totalOrdersFive.totalOrdersFive || 125) + ''), 1),
                    
                    changeType: growth,
                    changePercentValue: growthValue,
                    header: 'Orders',
                    subheader: 'Total orders for the day',
                });
            } else {
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/orders.png',
                    value: nFormatter(parseInt(( 125) + ''), 1),
                    changeType: 'nochange',
                    changePercentValue: null,
                    header: 'Orders ',
                    subheader: 'Total orders for the day',
                });
            }
            if (totalEmailFive) {
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/new.png',
                    value: nFormatter(parseInt((totalEmailFive.totalEmailFive || 0) + ''), 1),
                    changeType: 'new',
                    changePercentValue:nFormatter(parseInt((totalEmailFive.growthValue || 0) + ''), 1),
                    header: 'Email Scheduled',
                    subheader: 'Total Active SKUs ',
                });
            } else {
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/new.png',
                    value: nFormatter(parseInt(( 250) + ''), 1),
                    changeType:'nochange',
                    changePercentValue:null,
                    header: 'Email Scheduled',
                    subheader: 'Total Active Email Scheduled ',
                });
            }
    
            if (totalEmailSentFive) {
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/outsku.png',
                    value: nFormatter(parseInt((totalEmailSentFive.totalEmailSentFive || 0) + ''), 1),
                    changeType:  'new',
                    changePercentValue:  nFormatter(parseInt((totalEmailSentFive.growthValue || 0) + ''), 1),
                    header: 'Email Sent',
                    subheader: 'Total Active Email Sent ',
                });
    
            } else {
                data.push({
                    iconUrl: 'assets/icons/dashboard/kpis/outsku.png',
                    value: nFormatter(parseInt((200) + ''), 1),
                    changeType:'nochange',
                    changePercentValue:null,
                    header: 'Email Sent',
                    subheader: 'Total Active Email Sent ',
                });
            };
            return data;
        });





const getDashStoresSalesPerformance = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.StoreSalesPerformance> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.storeSalesPerformance
        }
        return null;
    });
const getDashOverDueOrder = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.OverdueOrders> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.overdueOrders
        }
        return null;
    });

const getDashTopSku = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.TopSku> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.topPerformingSku
        }
        return null;
    });

const getDashWorstSku = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.TopSku> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.worstPerformingSku
        }
        return null;
    });
const getDashInventoryStatus = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.InventoryStatus> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.inventoryStatus
        }
        return null;
    });
const getDashDispatchStatus = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.DispatchStatus> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.storeDispatchStatus
        }
        return null;
    });
const getDashDispatchStatusByMarketPlace = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.DispatchStatus> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.storeDispatchStatusByMarket
        }
        return null;
    });

const getOverDueOrderByMarketPlace = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.OverdueOrders> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.overdueOrdersByMarket
        }
        return null;
    });

const getMarketPlacePerformance = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.StoreSalesPerformance> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.marketplacePerformance
        }
        return null;
    });

const getSalesForecast = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.SalesForecast> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.salesForecast
        }
        return null;
    });


const getSalesMixByMarketPlace = createSelector(
    getSelectedStoreReport,
    (currentStoreReport: companyStoreReporModel.CompanyStoreReport):
        Array<companyStoreReporModel.SalesMix> => {
        if (currentStoreReport && currentStoreReport.report) {
            return currentStoreReport.report.salesMixByMarket
        }
        return null;
    });
export {
    getSelectedStore,
    getCurrentStoreId,
    getSelectedStoreReport,
    getCurrentStoreError, getCurrentStoreDateRange,
    getCompany,
    getCompanyStores, getCurrentCompanyStore, getCurrentCompanyStoreConfig,
    getDashSalesPerformance,
    getDashSalesMix,
    getDashMarketPlaceOVerview,
    getTooltipMessages,
    hasBrand,
    getDashKPI,
    getDashKPIFiveStar,
    // getForecastData,
    getDashStoresSalesPerformance,
    getDashOverDueOrder,
    getDashTopSku,
    getDashWorstSku,
    getDashInventoryStatus,
    getDashDispatchStatus,
    getDashDispatchStatusByMarketPlace,
    getSalesMixByMarketPlace,
    getMarketPlacePerformance,
    getSalesForecast,
    getOverDueOrderByMarketPlace,
    isStoreLoaded,
    getSalesGrowth
}

const nFormatter = (num, digits) => {
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
const getPercentValue = (perStr) => {

    if (perStr) {
        return parseInt((perStr || '').split('%')[0])
    }
    return null
}



const getGainValue = (value, status): { growthValue: string, growth: StatsChange } => {

    if (value == '0.00%') {
        return {
            growthValue: null,
            growth: 'nochange'
        }
    }
    else if (value == 'Infinity%') {
        return {
            growthValue: value,
            growth: 'gain'
        }
    }
    switch (status) {
        case 'UP':
            return {
                growthValue: value,
                growth: 'gain'
            }
            break;
        case 'DOWN':
            return {
                growthValue: value,
                growth: 'loss'
            }
            break;
        default:
            return {
                growthValue: value,
                growth: 'nochange'
            }
            break;
    }
}
// const getGainValue = (value): StatsChange => {


//     switch (value) {
//         case 'UP':
//             return 'gain'
//             break;
//         case 'DOWN':
//             return 'loss'
//             break;
//         default:
//             return 'nochange'
//             break;
//     }

// }
