import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { VisualizationService } from '../../shared/services/visualization.service';
import * as CompanyStoreReportAction from '../actions';
import * as UserAction from '../actions/user.action';
import { switchMap, catchError, map, mergeMap, tap, concatMap, exhaustMap, withLatestFrom, mapTo, first, combineAll } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
// import { CompanyStoreReportState } from '../reducers';
import * as companyStoreReporSelector from '../selectors';
import * as userSelector from '../selectors/user.selector';
import * as companyStoreReporModel from 'app/shared/models/report.model';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { UserService } from 'app/shared/services/user.service';
import { RepayService } from 'app/shared/services/repay.service';
import * as util from 'app/shared/utils/date.util';
export * from './faq.effect';

@Injectable()
export class RootEffects {

    compareDateRange(range1: companyStoreReporModel.DateRange, range2: companyStoreReporModel.DateRange) {

        if (!range1 || !range2) {
            return true;
        }


        const { endDate: endDate1, startDate: startDate1, dateType: dateType1 } = range1;
        const { endDate: endDate2, startDate: startDate2, dateType: dateType2 } = range2;

        return startDate1 == startDate2 && endDate1 == endDate2 && dateType1 == dateType2;

    }
    loadCompanyAllStores$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.loadCompanyAllStoreReport),
            //tap(() => { console.log("Invoked loadCompanyStores Api") }),
            withLatestFrom(
                this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId),
            ),
            switchMap(([{ payload }, report, storeId]) => {
            
                if (report && this.compareDateRange(payload.date_range, (report || {}).dateRange)&&report?.marketplace_id==payload.marketplace_id&&report.filter_store_id==payload.store_id&&report.country_code==payload.country_code) {
                    return of(CompanyStoreReportAction.loadCompanyStoreReportSuccess({ storeId: null }))
                } else {

                    let apis=[CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                        storeId: storeId,
                        report: {},
                        marketplace_id:payload.marketplace_id,
                        filter_store_id:payload.store_id,
                        country_code:payload.country_code,   
                        dateRange: {
                            endDate: payload ? payload.date_range.endDate : util.getCurrentDay(),
                            startDate: payload ? payload.date_range.startDate : util.getCurrentDay(),
                            dateType: payload ? payload.date_range.dateType : '7days',
                        }
                    }),
                    CompanyStoreReportAction.ApiGetSalesPerformance()];
          let sales_calls=report && this.compareDateRange(payload.date_range, (report || {}).dateRange)&&report.filter_store_id==payload.store_id;
                     let sales_kpis=[     
                        // CompanyStoreReportAction.ApiGetOverdueOrders(),
                        // CompanyStoreReportAction.ApiGetTopPerformingSku(),
                        // CompanyStoreReportAction.ApiGetWorstPerformingSku(),
                        // CompanyStoreReportAction.ApiGetInventoryStatus(),
                        CompanyStoreReportAction.ApiGetDispatchStatus()];

                        if(!sales_calls)
                        {
                            return [...apis,...sales_kpis]
                        }
                        return apis;
                    return [
                        CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                            storeId: storeId,
                            report: {},
                            marketplace_id:payload.marketplace_id,
                            filter_store_id:payload.store_id,
                            country_code:payload.country_code,   
                            dateRange: {
                                endDate: payload ? payload.date_range.endDate : util.getCurrentDay(),
                                startDate: payload ? payload.date_range.startDate : util.getCurrentDay(),
                                dateType: payload ? payload.date_range.dateType : '7days',
                            }
                        }),
                        // CompanyStoreReportAction.ApiGetTotalSales(),
                        // CompanyStoreReportAction.ApiGetTotalOrders(),
                        // CompanyStoreReportAction.ApiGetTotalOrdersFiveStar(),
                        // CompanyStoreReportAction.ApiGetActiveSKu(),
                        // CompanyStoreReportAction.ApiGetOutOfStuckSku(),
                        // CompanyStoreReportAction.ApiGetNewSku(),
                        CompanyStoreReportAction.ApiGetSalesPerformance(),
                        // CompanyStoreReportAction.ApiGetSalesMix(),
                        // CompanyStoreReportAction.ApiGetMarketPlaceOverview(),
                        // CompanyStoreReportAction.ApiGetTooltipMessages(),
                        // CompanyStoreReportAction.ApiGetStoreSalesPerformance(),


                        
                        CompanyStoreReportAction.ApiGetOverdueOrders(),
                        CompanyStoreReportAction.ApiGetTopPerformingSku(),
                        CompanyStoreReportAction.ApiGetWorstPerformingSku(),
                        CompanyStoreReportAction.ApiGetInventoryStatus(),
                        CompanyStoreReportAction.ApiGetDispatchStatus(),

                    ]



                }

            }),



        )
    });
    loadCompanyStores$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.loadCompanyStoreReport),
            //tap(() => { console.log("Invoked loadCompanyStores Api") }),
            withLatestFrom(
                this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId),
            ),
            switchMap(([{ payload }, report, storeId]) => {
                // console.log("payload sub store",payload,report,storeId)
                if (report && this.compareDateRange(payload.date_range, (report || {}).dateRange)&&report?.marketplace_id==payload.marketplace_id&&report.storeId==payload.store_id&&report.country_code==payload.country_code) {
                    return of(CompanyStoreReportAction.loadCompanyStoreReportSuccess({ storeId: null }))
                } else {
                    return [
                        CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                            storeId,
                            filter_store_id: payload.store_id,
                            marketplace_id:payload.marketplace_id,
                            country_code:payload.country_code,
                            report: {},
                            dateRange: {
                                endDate: payload ? payload.date_range.endDate : util.getCurrentDay(),
                                startDate: payload ? payload.date_range.startDate : util.getCurrentDay(),
                                dateType: payload ? payload.date_range.dateType : '7days',
                            }
                        }),
                        // CompanyStoreReportAction.ApiGetTotalSales(),
                        // CompanyStoreReportAction.ApiGetTotalOrders(),
                        // CompanyStoreReportAction.ApiGetTotalOrdersFiveStar(),
                        // CompanyStoreReportAction.ApiGetActiveSKu(),
                        // CompanyStoreReportAction.ApiGetOutOfStuckSku(),
                        CompanyStoreReportAction.ApiGetSalesPerformance(),
                        // CompanyStoreReportAction.ApiGetSalesMixByMarketPlace(),
                        // CompanyStoreReportAction.ApiGetMarketPlaceOverview(),
                        // CompanyStoreReportAction.ApiGetMarketPlacePerformance(),
                        // CompanyStoreReportAction.ApiGetTooltipMessages(),
                        // CompanyStoreReportAction.ApiGetOverdueOrdersByMarketplace(),
                        // CompanyStoreReportAction.ApiGetTopPerformingSku(),
                        // CompanyStoreReportAction.ApiGetWorstPerformingSku(),
                        // CompanyStoreReportAction.ApiGetInventoryStatus(),
                        // CompanyStoreReportAction.ApiGetDispatchByMarketPlaceStatus(),
                        // CompanyStoreReportAction.ApiSaleForecast(),
                    ]



                }

            }),



        )
    });

    getUserProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserAction.GetUserProfile),
            //tap(() => { console.log("Invoked getUserStores Api") }),
            switchMap(() => {
                return this.dashboardService.getUserProfile().pipe(
                    // map(
                    //     (response) =>
                    //         UserAction.SetUserProfile(response.response_body)
                    // ),
                    //tap(() => { console.log("Completed getUserProfile Api") }),
                    switchMap((response) => [
                        UserAction.SetUser({ payload: response.response_body.user }),
                        UserAction.SetUserPrivilege({ payload: response.response_body.privileges }),
                        CompanyStoreReportAction.ApiGetUserStore()
                    ]),
                    catchError((error) => of(UserAction.UserProfileFailure(error))),

                )

            }),

        )
    });
    updateUserProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserAction.UpdateUser),
            first(),
            //tap(() => { console.log("Invoked getUserStores Api") }),
            switchMap((data) => {
                return this.userService.updateAccountDetailsByUserId(data.payload).pipe(

                    switchMap((response) => [
                        UserAction.SetUser({ payload: response.response_body }),
                    ]),
                    catchError((error) => of(UserAction.UserProfileFailure(error))),

                )

            }),

        )
    });
    getSalesGrowth$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.LoadSalesGrowth),
            //tap(() => { console.log("Invoked getUserStores Api") }),
            
            mergeMap(({payload}) => {
                
                
                return this.dashboardService.getSalesGrowth(payload.id,payload.year,'',payload.marketplace_id,payload.country_code).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.ApiSetSalesGrowth({ payload: {data:response.response_body,store_id:payload.id} })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalSales Api") })
                )

            })
        )
    });
    getUserStores$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetUserStore),
            //tap(() => { console.log("Invoked getUserStores Api") }),
            withLatestFrom(this.store.select(userSelector.getUser)),
            map((data) => {
                // console.log("getUserStores", data)
                return data[1];
            }),
            mergeMap(({ id }) => {
                return this.dashboardService.getStoresByUserId(id, 1).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.ApiSetUserStore({ payload: response.response_body })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalSales Api") })
                )

            })
        )
    });
    /*
    To handle the behaviour of the Effect when different Action instances
    occurs on the same effect you can change mergeMap to other operators
    */

    // effect getTotalSales an API call success


    getMarketPlacePerformance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetMarketPlacePerformance),
            //tap(() => { console.log("Invoked getTotalSales Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                // console.log("action, report, storeId", action, report, storeId)
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getMarketplacePerformance(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    marketplacePerformance: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalSales Api") })
                )

            })
        )
    });

    getSaleForecast$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiSaleForecast),
            //tap(() => { console.log("Invoked getTotalSales Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                // console.log("action, report, storeId", action, report, storeId)
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getForecastByStoreId(storeId).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    salesForecast: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalSales Api") })
                )

            })
        )
    });

    getTotalSales$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetTotalSales),
            //tap(() => { console.log("Invoked getTotalSales Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                // console.log("action, report, storeId", action, report, storeId)
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
               
               
                // this.visualizationApi.getCampaignAnalyticsOrders().toPromise().then(res =>{
                //     console.log(res);
                //     console.log("**************************************************");
                    
                // });
               
                return this.visualizationApi.getTotalSales(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    totalSales: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalSales Api") })
                )

            })
        )
    });
    getTotalOrders$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetTotalOrders),
            //tap(() => { console.log("Invoked getTotalOrders Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getTotalOrders(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    totalOrders: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalOrders Api") })
                )

            })
        )
    });
    // Five star
    getTotalOrdersFiveStar$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetTotalOrdersFiveStar),
            //tap(() => { console.log("Invoked getTotalOrders Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getTotalOrdersFiveStar(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    totalOrders: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTotalOrders Api") })
                )

            })
        )
    });
    getActiveSKu$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetActiveSKu),
            //tap(() => { console.log("Invoked getActiveSKu Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;

                return this.visualizationApi.getActiveSKu(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>{
                            let finalData = {
                                activeSku: {
                                    totalInventory:'0',
                                    growthValue:'0',
                                    growth: 'nochange'
                                },
                                outOfStockSku:{
                                    totalInventory:'0',
                                    growthValue: '0',
                                    growth: 'nochange'
                                },
                            };



                            if(response.response_body){
                                const {
                                    outOfStock,
                                    newSkuOutOfStock,
                                    activeStock,
                                    newSkuActive
                                } = response.response_body;

                                if(activeStock){
                                    finalData.activeSku.totalInventory = activeStock;
                                    finalData.activeSku.growthValue = newSkuActive;
                                }
                                if(outOfStock){
                                    finalData.outOfStockSku.totalInventory = outOfStock;
                                    finalData.outOfStockSku.growthValue = newSkuOutOfStock;
                                }
                            }



                         return   CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    activeSku: finalData.activeSku,
                                    outOfStockSku: finalData.outOfStockSku
                                },

                            })

                        }
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getActiveSKu Api") })
                )

            })
        )
    });
    getOutOfStockSku$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetOutOfStuckSku),
            //tap(() => { console.log("Invoked getOutOfStockSku Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getOutOfStockSku(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    outOfStockSku: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getOutOfStockSku Api") })
                )

            })
        )
    });
    getNewSku$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetNewSku),
            //tap(() => { console.log("Invoked getOutOfStockSku Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getOutOfStockSku(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    newSku: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getOutOfStockSku Api") })
                )

            })
        )
    });
    getSalesPerformance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetSalesPerformance),
            //tap(() => { console.log("Invoked getSalesPerformance Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange,filter_store_id,marketplace_id,country_code } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getSalesPerformance(storeId, startDate, endDate,filter_store_id,marketplace_id,country_code).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    salesPerformance: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getSalesPerformance Api") })
                )

            })
        )
    });
    getSalesMix$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetSalesMix),
            //tap(() => { console.log("Invoked getSalesMix Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange,filter_store_id,marketplace_id,country_code } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getSalesMix(storeId, startDate, endDate,filter_store_id,marketplace_id,country_code).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    salesMix: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getSalesMix Api") })
                )

            })
        )
    });

    getSalesMixByMarketPlace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetSalesMixByMarketPlace),
            //tap(() => { console.log("Invoked getSalesMix Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getSalesMixByMarketplace(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    salesMixByMarket: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getSalesMix Api") })
                )

            })
        )
    });
    getDispatchStatusByMarketplaceId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetDispatchByMarketPlaceStatus),
            //tap(() => { console.log("Invoked getSalesMix Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getDispatchStatusByMarketplaceId(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    storeDispatchStatusByMarket: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getSalesMix Api") })
                )

            })
        )
    });
    getDispatchStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetDispatchStatus),
            //tap(() => { console.log("Invoked getSalesMix Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange,marketplace_id,filter_store_id } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getDispatchStatus(storeId, startDate, endDate,filter_store_id,marketplace_id).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                marketplace_id,
                                filter_store_id,
                                report: {
                                    storeDispatchStatus: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getSalesMix Api") })
                )

            })
        )
    });


    getMarketPlaceOverview$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetMarketPlaceOverview),
            //tap(() => { console.log("Invoked getMarketPlaceOverview Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getMarketPlaceOverview(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    marketPlaceOverview: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getMarketPlaceOverview Api") })
                )

            })
        )
    });

    // getTooltipMessages$ = createEffect(() => {


    //     return this.actions$.pipe(
    //         ofType(CompanyStoreReportAction.ApiGetTooltipMessages),
    //         //tap(() => { console.log("Invoked getMarketPlaceOverview Api") }),
    //         withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
    //             this.store.select(companyStoreReporSelector.getCurrentStoreId)),

    //         switchMap(([action, report, storeId]) => {
    //             // const { dateRange } = report;
    //             // const { startDate, endDate } = dateRange;

    //             return this.visualizationApi.getTooltipMessages(storeId).pipe(
    //                 map(
    //                     (response) =>
    //                         CompanyStoreReportAction.loadCompanyStoreReportSuccess({
    //                             storeId,
    //                             report: {
    //                                 tooltipMessages: response.response_body
    //                             },

    //                         })
    //                 ),
    //                 catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
    //                 //tap(() => { console.log("Completed getMarketPlaceOverview Api") })
    //             )

    //         })
    //     )
    // });
    getStoreSalesPerformance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetStoreSalesPerformance),
            //tap(() => { console.log("Invoked getStoreSalesPerformance Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getStoreSalesPerformance(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    storeSalesPerformance: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getStoreSalesPerformance Api") })
                )

            })
        )
    });
    getOverdueOrders$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetOverdueOrders),
            //tap(() => { console.log("Invoked getOverdueOrders Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getOverdueOrders(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    overdueOrders: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getOverdueOrders Api") })
                )

            })
        )
    });
    getOverdueOrdersByMarketplace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetOverdueOrdersByMarketplace),
            //tap(() => { console.log("Invoked getOverdueOrders Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getOverdueOrdersByMarketplace(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    overdueOrdersByMarket: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getOverdueOrders Api") })
                )

            })
        )
    });
    getTopPerformingSku$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetTopPerformingSku),
            //tap(() => { console.log("Invoked getTopPerformingSku Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange,filter_store_id,marketplace_id } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getTopPerformingSku(storeId, startDate, endDate,filter_store_id,marketplace_id).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    topPerformingSku: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getTopPerformingSku Api") })
                )

            })
        )
    });
    getWorstPerformingSku$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetWorstPerformingSku),
            //tap(() => { console.log("Invoked getWorstPerformingSku Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange ,filter_store_id,marketplace_id} = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getWorstPerformingSku(storeId, startDate, endDate,filter_store_id,marketplace_id).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    worstPerformingSku: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getWorstPerformingSku Api") })
                )

            })
        )
    });
    getInventoryStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyStoreReportAction.ApiGetInventoryStatus),
            //tap(() => { console.log("Invoked getInventoryStatus Api") }),
            withLatestFrom(this.store.select(companyStoreReporSelector.getSelectedStoreReport),
                this.store.select(companyStoreReporSelector.getCurrentStoreId)),

            switchMap(([action, report, storeId]) => {
                const { dateRange } = report;
                const { startDate, endDate } = dateRange;
                return this.visualizationApi.getInventoryStatus(storeId, startDate, endDate).pipe(
                    map(
                        (response) =>
                            CompanyStoreReportAction.loadCompanyStoreReportSuccess({
                                storeId,
                                report: {
                                    inventoryStatus: response.response_body
                                },

                            })
                    ),
                    catchError((error) => of(CompanyStoreReportAction.loadCompanyStoreReportFailure(error))),
                    //tap(() => { console.log("Completed getInventoryStatus Api") })
                )

            })
        )
    });
    constructor(private actions$: Actions, private userService: UserService, private repayService: RepayService, private dashboardService: DashboardService, private visualizationApi: VisualizationService, private store: Store<companyStoreReporModel.CompanyStoreReportState>) { }
}
