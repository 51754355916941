import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ɵBrowserDomAdapter } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonDataObservableService } from './commondata.service'

import { Store, select } from '@ngrx/store';
import { fromRoot } from '../../store';
import * as userModel from 'app/shared/models/user.model';
import { TRACKER_TYPES } from 'tracker-enums';
import { MixpanelTrackingService } from './mixpanel-tracking.service';

// import { BrowserDomAdapter } from 'angular2/platform/browser';
interface User {
  email: string;
  password: string
}

interface Password {
  password: string;
}

interface UserResponse {

  response_code: number
  response_message: string;
  response_body: any;
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public httpOption;
  constructor(public http: HttpClient, private router: Router,
    private commonDataService: CommonDataObservableService,
    private store: Store<{ user: userModel.UserProfile }>,private trackService:MixpanelTrackingService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    window.addEventListener('message', this.onPostMessage, false);
  }


  // authorization code and tokens
  authorizationCode: string;
  oAuthToken: string;
  oAuthVerifier: string;

  // popup related
  private windowHandle: Window;   // reference to the window object we will create    
  private intervalId: any = null;  // For setting interval time between we check for authorization code or token
  private loopCount = 600;   // the count until which the check will be done, or after window be closed automatically.
  private intervalLength = 100;   // the gap in which the check will be done for code.

  private windowObjectReference = null;
  private previousUrl = null;

  doAuthorization(url: string, isRegisterAction: boolean) {
    /* isRegisterAction flag i am using to check if the process is for registration or Login */
    /* socialMediaProvider is for name of social media , it is optional*/

    let loopCount = this.loopCount;

    /* Create the window object by passing url and optional window title */
    this.windowHandle = this.createOauthWindow(url);

    /* Now start the timer for which the window will stay, and after time over window will be closed */
    this.intervalId = window.setInterval(() => {
      if (loopCount-- < 0) {
        window.clearInterval(this.intervalId);
        this.windowHandle.close();
      } else {
        let href: string;  // For referencing window url
        try {
          href = this.windowHandle.location.href; // set window location to href string
        } catch (e) {
          // //console.log('Error:', e); // Handle any errors here
        }
        if (href != null) {

          // Method for getting query parameters from query string
          const getQueryString = function (field: any, url: string) {
            const windowLocationUrl = url ? url : href;
            const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
            const string = reg.exec(windowLocationUrl);
            return string ? string[1] : null;
          };
          /* As i was getting code and oauth-token i added for same, you can replace with your expected variables */
          if (href.match('code')) {
            // for google , fb, github, linkedin
            window.clearInterval(this.intervalId);
            this.authorizationCode = getQueryString('code', href);
            this.windowHandle.close();
            if (isRegisterAction) {
              /* call signup method */
              // //console.log("isRegistered code", this.authorizationCode)
            } else {
              /* call login method */
              // //console.log("isLogin code", this.authorizationCode)
            }
          } else if (href.match('oauth_token')) {
            // for twitter
            window.clearInterval(this.intervalId);
            this.oAuthToken = getQueryString('oauth_token', href);
            this.oAuthVerifier = getQueryString('oauth_verifier', href);
            this.windowHandle.close();
            if (isRegisterAction) {
              /* call signup */
              // //console.log("isRegistered", this.oAuthToken, this.oAuthVerifier)
            } else {
              /* call login */
              // //console.log("isLogin", this.oAuthToken, this.oAuthVerifier)
            }
          }
        } else {
          // //console.log("has no data")
        }
      }
    }, this.intervalLength);
  }

  createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
    if (url == null) {
      return null;
    }
    const options = `width=${width},height=${height},left=${left},top=${top}`;
    return window.open(url, name, options);
  }


  private handleError(errorRespose: HttpErrorResponse) {
    // //console.log("has error", errorRespose)
    if (errorRespose.error instanceof ErrorEvent) {
      //console.log("has error 1", errorRespose)
      return throwError(errorRespose.error);
    } else {
      if (errorRespose.hasOwnProperty('error')) {
        //console.log("has error 2", errorRespose)
        return throwError(errorRespose.error);
      } else {
        //console.log("has error 4", errorRespose)
        return throwError({
          message: 'Internal Server Error'
        });
      }
    }
  }

  // getSponsor(sponsorId: string): Observable<SponsorResponse> {
  //   return this.http.get<SponsorResponse>(environment.baseUrl + serviveUrl.getSponsor + `/${sponsorId}`).pipe(catchError(this.handleError));
  // }


  login(data: User): Observable<UserResponse> {
    const { email, password } = data;
    const body = {
      email, password
    }
    return this
      .http
      .post<UserResponse>(environment.baseUrl + serviveUrl.userLogin, body).pipe(catchError(this.handleError));
  }
  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': localStorage.getItem('refresh_token') })
    };
    return this.http.get(environment.baseUrl  +  serviveUrl.refreshToken, httpOptions).pipe(catchError(this.handleError));
    // return this
    //   .http
    //   .post<UserResponse>(environment.baseUrl + serviveUrl.userLogin, body).pipe(catchError(this.handleError));
  }
  register(data: User): Observable<UserResponse> {
    const { email, password } = data;
    const body = {
      email, password
    }
    return this
      .http
      .post<UserResponse>(environment.baseUrl + serviveUrl.userRegistration, body).pipe(catchError(this.handleError));
  }

  activateUser(token: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(environment.baseUrl + serviveUrl.userActivateToken + token).pipe(catchError(this.handleError));
  }

  verifyResetToken(token: string): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.verifyResetPasswordToken + token).pipe(catchError(this.handleError));
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.forgetPassword + email).pipe(catchError(this.handleError));
  }
  updatePassword(token: string, password: string): Observable<any> {
    const body = {
      password
    }
    return this.http.post(environment.baseUrl + serviveUrl.updatePassword + token, body).pipe(catchError(this.handleError));
  }




  popupWindow(win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
    const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
    return `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`;
  }
  googleLogin() {
    // this.doAuthorization(environment.baseUrl + serviveUrl.googleLogin, false)
    this.openSignInWindow(environment.baseUrl + serviveUrl.googleLogin, 'google');
    // var options = 'left=100,top=10,width=400,height=500';
    // window.open(environment.baseUrl + serviveUrl.googleLogin, "_blank", options);
  }

  googleRegister() {
    // var options = 'left=100,top=10,width=400,height=500';
    // window.open(environment.baseUrl + serviveUrl.googleRegistration, "_blank", options);
    this.openSignInWindow(environment.baseUrl + serviveUrl.googleRegistration, 'google');
  }

  onPostMessage(event) {
    // //console.log("event", event)
    if (event.data.status === "200") {
      // Use an EventEmitter to notify the other components that user logged in
      // this._loggedInService.Stream.emit(null);
    }
  }
  // validateLoginOtp(data: { username: string, otp: string, conferenceId: string }): Observable<any> {
  //   const body = data;
  //   return this
  //     .http
  //     .post(environment.baseUrl + serviveUrl.getAuthentication, body).pipe(catchError(this.handleError));
  // }


  // removeUser(data: { conferenceId: string, userId: string }): Observable<any> {
  //   const body = {
  //     meeting: {
  //       id: data.userId
  //     }
  //   }
  //   return this.http.post(environment.baseUrl + serviveUrl.removeUserFromConference + data.conferenceId, body).pipe(catchError(this.handleError));
  // }



  openSignInWindow = (url, name) => {
    // remove any existing event listeners
    window.removeEventListener('message', this.receiveMessage);

    // window features
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (this.windowObjectReference === null || this.windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      this.windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (this.previousUrl !== url) {
      /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
      this.windowObjectReference = window.open(url, name, strWindowFeatures);
      this.windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
       is not closed; therefore, we can bring it back on top of any other
       window with the focus() method. There would be no need to re-create
       the window or to reload the referenced resource. */
      this.windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', event => this.receiveMessage(event), false);
    // assign the previous URL
    this.previousUrl = url;
  }

  receiveMessage = event => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    // if (event.origin !== BASE_URL) {
    //   return;
    // }
    const { data } = event;
    // if we trust the sender and the source is our popup
    // if (data.source === 'lma-login-redirect') {
    // get the URL params and redirect to our server to use Passport to auth/login
    // const { payload } = data;
    // const redirectUrl = `/auth/google/login${payload}`;
    // window.location.pathname = redirectUrl;
    this.processGoogleData(data);
    // this.router.navigate(['/dashboard']);
    this.router.navigate(["/dashboard", "summary"])
    // this.windowObjectReference.close();
    window.removeEventListener('message', this.receiveMessage);
    // }
  };


  processGoogleData(payload: any) {
    
    if (payload) {
      
      const { response_body, service } = payload;
      if (service == "google") {
        if (response_body) {
          const { user, token, privileges } = response_body;
          this.store.dispatch(fromRoot.SetUser({ payload: user }));
          this.store.dispatch(fromRoot.SetUser({ payload: privileges }));
          this.trackService.track({type:TRACKER_TYPES.account.sign_up,data:{email:user.email}})
          // this.windowObjectReference.postMessage({ data: "somedate" }, "*");
          if (user) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          if (token) {
            localStorage.setItem("token", token);
          }
          this.trackService.track({type:TRACKER_TYPES.account.sign_up,data:{email:user.email}})
        }
        if(this.windowObjectReference){
          this.windowObjectReference.close();
        }
      }
    }
  }
}
