import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as faqAction from '../actions/faq.action';
import { IFaqReducer } from '../../shared/models/faq.model';

const initialFaqReducer: IFaqReducer = {
    list: [],
    loading: false,
    error: null
}


export const faqReducer = createReducer(
    initialFaqReducer,
    on(faqAction.LoadAllFaqs, (state: IFaqReducer) => {
        return { ...state, loading: true };
    }), 
    on(faqAction.SaveAllFaqs, (state: IFaqReducer, a) => {

        return { ...state, loading: false, list: a.payload };
    }), 
);
