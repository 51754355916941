export const TRACKER_TYPES={
account:{
         sign_up:'Sign Up',
         sign_in:'Sign In'
        },
repay:{
      selected_inbound_shipment:'Selected Inbound Shipment',
      select_repay_tab:'Select Repay Tab',
      search_by_shipment_id:'Search by Shipment id',
      shipment_details:'Shipment Details',
      reassign_shipment:'Reassign Shipment',
      revert_case:'Revert Case',
      open_case:'Open Case',
      raised_case:'Raised Case',
      export_excel:'Export Excel',
      dismiss_shipment:'Dismiss Shipment',
      update_shipment_remarks:'Updated Shipment info'
      }
    }