import { Component } from '@angular/core';
import { ToastService } from './shared/component/toaster/toast.service';
import { Store, select } from '@ngrx/store';
import { fromRoot } from './store';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ModalService } from './shared/component/modal/modal.service';
import { HostListener } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @HostListener('window:popstate', ['$event'])
onPopState(event) {
  location.reload()
}
  loading = false;
  title = 'xteremecommerce';
  constructor(
    private router: Router,
    private modalService:ModalService) {
    router.events.subscribe(event => {     
      if(event instanceof NavigationStart) {
        this.loading = true;
      }else if(event instanceof NavigationEnd) {
        this.loading = false;
        this.modalService.closeAll();
      }
    })
  }
 

}
