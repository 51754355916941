import { BehaviorSubject } from "rxjs"
import { User } from "../models"

enum ERole {
    SUPER_ADMIN = "super_admin",
    COMPANY_ADMIN = "company_admin",
    COMPANY_OPERATION = "company_operation",
    COMPANY_VIEW_ONLY = "company_view_only",
    STORE_ADMIN = "store_admin",
    STORE_OPERATION = "store_operation",
    STORE_VIEW_ONLY = "store_view_only",
}

enum EPrivilege {
    COMPANY_USERS = "company_users",
    ADD_USER = "add_user",
    REMOVE_USER = "remove_user",
    UPDATE_USER = "update_user",
    RESET_PASSWORD = "reset_password",
    DASHBOARD = "dashboard",
    COMPANY_DASHBOARD_ACCESS = "company_dashboard_access",
    STORE_DASHBOARD_ACCESS = "store_dashboard_access",
    PPC_DASHBOARD = "ppc_dashboard",
    REPRICER_DASHBOARD = "repricer_dashboard",
    INTEGRATIONS = "integrations",
    ADD_INTEGRATIONS = "add_integrations",
    REMOVE_INTEGRATIONS = "remove_integrations",
    UPDATE_INTEGRATIONS = "update_integrations",
    SERVICES = "services",
    ADD_SERVICES = "add_service",
    REMOVE_SERVICES = "remove_service",
    UDPATE_SERVICES = "update_service",
    STORES = "stores",
    ADD_STORES = "add_store",
    REMOVE_STORES = "remove_store",
    UDPATE_STORES = "update_store",
    STORE_USERS = "store_users",
    ADD_STORE_USERS = "add_store_users",
    REMOVE_STORE_USERS = "remove_store_users",
    UPDATE_STORE_USERS = "udpate_store_users",
    RESET_PASSWORD_STORE_USERS = "store_user_reset_password",
    VIEW_USERS_LIST = "view_users_list",
    VIEW_SERVICES_LIST = "view_services_list",
    VIEW_INTEGRATIONS_LIST = "view_integrations_list",
    VIEW_STORES_LIST = "view_stores_list",
    VIEW_STORE_USERS_LIST = "view_store_users_list",
    VIEW_ROLES = "view_roles",
    ADD_ROLES = "add_roles",
    REMOVE_ROLES = "remove_roles",
    UPDATE_ROLES = "udpate_roles",
    EDIT_COMPANY_PROFILE = "edit_company_profile",
    EDIT_STORE_PROFILE = "edit_store_profile",
    VIEW_SINGLE_STORE = "view_single_store",
    VIEW_BILLING_DETAILS = "view_billing_details",
    UPGRADE_STORE_APPLICATION = "upgrade_store_application",
}

class RoleAndPermissions {
    listener$ = new BehaviorSubject<User>(null)
    me$: User;

    constructor() {
        const user = localStorage.getItem('user');
        if (user) this.setUser(JSON.parse(user));
    }

    setUser (u: User) {
        
        this.me$ = u;
        this.listener$.next(u);
    }

    get isPaymentDone (): boolean {
        return this.me$.payment && this.me$.payment.id > 0
    }

    get isSuperAdmin (): boolean {
        return this.me$.role.role === ERole.SUPER_ADMIN
    }

    get isCompanyAdmin (): boolean {
        return this.me$.role.role === ERole.COMPANY_ADMIN
    }

    get isCompanyOperation (): boolean {
        return this.me$.role.role === ERole.COMPANY_OPERATION
    }

    get isCompanyViewOnly (): boolean {
        return this.me$.role.role === ERole.COMPANY_VIEW_ONLY
    }

    get isStoreAdmin (): boolean {
        return this.me$.role.role === ERole.STORE_ADMIN
    }

    get isStoreOperation (): boolean {
        return this.me$.role.role === ERole.STORE_OPERATION
    }

    get isStoreViewOnly (): boolean {
        return this.me$.role.role === ERole.STORE_VIEW_ONLY
    }

    get companyUsers (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.COMPANY_USERS)
    }

    get addUser  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_USER)
    }

    get removeUser  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_USER)
    }

    get updateUser  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UPDATE_USER)
    }

    get resetPassword  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.RESET_PASSWORD)
    }

    get dashboard  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.DASHBOARD)
    }

    get companyDashboardAccess  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.COMPANY_DASHBOARD_ACCESS)
    }

    get storeDashboardAccess  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.STORE_DASHBOARD_ACCESS)
    }

    get ppcDashboard  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.PPC_DASHBOARD)
    }

    get repricerDashboard  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REPRICER_DASHBOARD)
    }

    get integrations  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.INTEGRATIONS)
    }

    get addIntegrations  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_INTEGRATIONS)
    }

    get removeIintegrations  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_INTEGRATIONS)
    }

    get updateIntegrations  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UPDATE_INTEGRATIONS)
    }

    get services  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.SERVICES)
    }

    get addService  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_SERVICES)
    }

    get removeService  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_SERVICES)
    }

    get updateService  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UDPATE_SERVICES)
    }

    get stores  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.STORES)
    }

    get addStore  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_STORES)
    }

    get removeStore  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_STORES)
    }

    get updateStore  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UDPATE_STORES)
    }

    get storeUsers  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.STORE_USERS)
    }

    get addStoreUsers  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_STORE_USERS)
    }

    get removeStoreUsers  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_STORE_USERS)
    }

    get udpateStoreUsers  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UPDATE_STORE_USERS)
    }

    get storeUserResetPassword  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.RESET_PASSWORD_STORE_USERS)
    }

    get viewUsersList () {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_USERS_LIST)
    }

    get viewServicesList () {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_SERVICES_LIST)
    }

    get viewIntegrationsList () {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_INTEGRATIONS_LIST)
    }

    get viewStoresList () {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_STORES_LIST)
    }

    get viewStoreUsersList () {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_STORE_USERS_LIST)
    }

    get viewRoles  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_ROLES)
    }

    get addRole  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.ADD_ROLES)
    }

    get removeRole  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.REMOVE_ROLES)
    }

    get udpateRole  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UPDATE_ROLES)
    }

    get updateCompanyProfile  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.EDIT_COMPANY_PROFILE)
    }

    get updateStoreProfile  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.EDIT_STORE_PROFILE)
    }

    get viewSingleStore  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_SINGLE_STORE)
    }

    get viewBillingDetails  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.VIEW_BILLING_DETAILS)
    }

    get upgradeStoreApplication  (): boolean {
        return this.me$.privileges.some(p => p.privilege === EPrivilege.UPGRADE_STORE_APPLICATION)
    }
}

export const rap$ = new RoleAndPermissions();
