import { createAction, props } from '@ngrx/store';
import { IFaq } from 'app/interfaces';

const PREFIX = `[Xtreme] [Faq]`;

const LOAD_ALL_FAQS = `${PREFIX} Get Faqs List`;

const SAVE_ALL_FAQS = `${PREFIX} Save Faqs List`;

export const LoadAllFaqs = createAction(
    LOAD_ALL_FAQS
);

export const SaveAllFaqs = createAction(
    SAVE_ALL_FAQS,
    props<{ payload: IFaq[]}>()
);
