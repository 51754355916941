import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as mixpanel from 'mixpanel-browser';
import * as moment from 'moment';
import { rap$ } from '../roles';
@Injectable(
{
  providedIn: 'root'
}
)

export class MixpanelTrackingService {

  constructor() { 
    mixpanel.init(environment.mixpanel_project_token);
  
   
  }
  
  track({type,data})
  {
    if(environment.production)
    {
    let user:any=localStorage.getItem('user');
    if(user){
      user=JSON.parse(user);
      mixpanel.identify(user.email);
      let user_data={"$email":user.email}
      if(user.first_name)
      {
        user_data["$name"]=user.first_name.concat(' ',(user.last_name||''));
      }
      mixpanel.people.set_once(user_data); 
    }
    else{
      mixpanel.identify(data.email);
      
      let user_data={"$email":data.email}
      if(data.first_name)
      {
        user_data["$name"]=data.first_name.concat(' ',(data.last_name||''));
      }
      mixpanel.people.set_once(user_data); 
    }
    mixpanel.track(type, {...data,action_time:moment().toLocaleString()});}
  }
  
  
  
}
