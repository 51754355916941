import { createAction, props } from '@ngrx/store';
import * as userModel from '../../shared/models/user.model'


const PREFIX = `[Xtreme] [User]`;


const USER_CURRENT_SELECTION = `${PREFIX} Current Selection`;

const SET_USER = `${PREFIX} Set User`;
const UPDATE_USER = `${PREFIX} Update User`;
const SET_USER_PRIVILEGES = `${PREFIX} Set User Privileges`;
const UPDATE_USER_PROFILE = `${PREFIX} Update User Profile`;


const GET_USER_PROFILE = `${PREFIX} Get User Profile`;

const USER_PROFILE_ERROR = `${PREFIX} User Profile Error`;

export const SetUser = createAction(
    SET_USER, props<{payload:userModel.User}>()
);
export const UpdateUser = createAction(
    UPDATE_USER, props<{payload:userModel.Account}>()
);

export const SetUserPrivilege = createAction(
    SET_USER_PRIVILEGES, props<{payload:Array<userModel.Privilege>}>()
);
export const UpdateUserProfile = createAction(
    UPDATE_USER, props<{payload:userModel.Account}>()
);
export const GetUserProfile = createAction(
    GET_USER_PROFILE
);

export const UserProfileFailure = createAction(
    USER_PROFILE_ERROR,
    props<{ error: any }>()
);
