import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ɵBrowserDomAdapter } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonDataObservableService } from './commondata.service'

// import { BrowserDomAdapter } from 'angular2/platform/browser';
interface User {
  email: string;
  password: string
}


interface CommonResponse {

  response_code: number
  response_message: string;
  response_body: any;
}


@Injectable({
  providedIn: 'root'
})
export class RepayService {
selected_marketplace=new BehaviorSubject(0);
  repayDashboard = {
    cardsData : [],
    performaceData : [],
    aggregatedAmountData : []

  }

  operationalUsers=[];
  public httpOption;
  constructor(public http: HttpClient, private router: Router, private commonDataService: CommonDataObservableService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };


  }


  // authorization code and tokens
  authorizationCode: string;
  oAuthToken: string;
  oAuthVerifier: string;

  private handleError(errorRespose: HttpErrorResponse) {
    if (errorRespose.error instanceof ErrorEvent) {
      return throwError(errorRespose.error);
    } else {
      if (errorRespose.hasOwnProperty('error')) {
        return throwError(errorRespose.error);
      } else {
        return throwError({
          message: 'Internal Server Error'
        });
      }
    }
  }

  getRepayCaseManager(data: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getRepayCaseManager + data.userId + `?sort=${data.sort}&order=${data.order}&page=${data.page + 1
      }&search_param=${data.search_param || ''}`).pipe(catchError(this.handleError));
  }
  
  reAssignShipment(store_id,data)
  {
    return this.http.post<CommonResponse>(environment.baseUrl + serviveUrl.getreAssignCase  + store_id,data).pipe(catchError(this.handleError));
  }
  getPOsList(data)
  {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getPOsList + data.userId + `?&all_items=${true}&assigned_to=${data.assigned_to}&start_date=${data.start_date}&end_date=${data.end_date}`).pipe(catchError(this.handleError));
  }

  getInboundCaseManager(data: any): Observable<CommonResponse> {
    const page_size=`&page_size=${data.page_size}`;
    const dates= `&from_date=${data.from_date}&to_date=${data.to_date}`;
    const si_id=`&service_integration_id=${data.si_id}`;
    const filters=page_size+dates+si_id;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInboundCaseManager + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}&status=${data.status}&assigned_to=${data.assigned_to}`).pipe(catchError(this.handleError));
  }
  getFBACaseManager(data: any): Observable<CommonResponse> {
    const page_size=`&page_size=${data.page_size}`;
    const dates= `&from_date=${data.from_date}&to_date=${data.to_date}`;
    const si_id=`&service_integration_id=${data.si_id}`;
    const filters=page_size+dates+si_id;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getFBACaseManager + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}&status=${data.status}&assigned_to=${data.assigned_to}`).pipe(catchError(this.handleError));
  }
  getLostCaseManager(data: any): Observable<CommonResponse> {
    const page_size=`&page_size=${data.page_size}`;
    const dates= `&from_date=${data.from_date}&to_date=${data.to_date}`;
    const si_id=`&service_integration_id=${data.si_id}`;
    const filters=page_size+dates+si_id;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getLostCaseManager + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}&status=${data.status}&assigned_to=${data.assigned_to}`).pipe(catchError(this.handleError));
  }
  getDamagedCaseManager(data: any): Observable<CommonResponse> {
    const page_size=`&page_size=${data.page_size}`;
    const dates= `&from_date=${data.from_date}&to_date=${data.to_date}`;
    const si_id=`&service_integration_id=${data.si_id}`;
    const filters=page_size+dates+si_id;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getDamagedCaseManager + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}&status=${data.status}&assigned_to=${data.assigned_to}`).pipe(catchError(this.handleError));
  }

  getShipmentsList(data: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getShipmentsList + data.userId + `?sort=${data.sort}&order=${data.order}&page=${data.page + 1
      }&search_param=${data.search_param || ''}`).pipe(catchError(this.handleError));
  }
  getInventory(data: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventory + data.userId + `?sort=${data.sort}&order=${data.order}&page=${data.page + 1
      }&search_param=${data.search_param}`).pipe(catchError(this.handleError));
  }

  getEstimatedFeesListings(data: any): Observable<CommonResponse> {
    const si_id=`&service_integration_id=${data.si_id}`;
    const page_size=`&page_size=${data.page_size}`;
    const filters=si_id+page_size;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getListings + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}`).pipe(catchError(this.handleError));
  }
  getInventoryListing(data: any): Observable<CommonResponse> {
    const si_id=`&service_integration_id=${data.si_id}`;
    const page_size=`&page_size=${data.page_size}`;
    const filters=si_id+page_size;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventory + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}`).pipe(catchError(this.handleError));

  }

  getShipmentListing(data: any): Observable<CommonResponse> {
    const si_id=`&service_integration_id=${data.si_id}`;
    const page_size=`&page_size=${data.page_size}`;
    const filters=si_id+page_size;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getShipmentsList + data.userId + `?page=${data.page + 1}${filters}&order=${data.order}&sort=${data.sort}&search_param=${data.search_param || ''}&status=${data.status}&assigned_to=${data.assigned_to}`).pipe(catchError(this.handleError));
  }

  getInventoryItem(managerId: string, userId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventoryItem + managerId + '/' + userId).pipe(catchError(this.handleError));
  }
  getFBAFeesDetail(managerId: string, userId: string)
  {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getFBAListingsItem + managerId + '/' + userId).pipe(catchError(this.handleError));
  }
  getInventoryHistory(managerId: string, userId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventoryHistory + managerId + '/' + userId).pipe(catchError(this.handleError));
  }
  getInventoryReimbursements(managerId: string, userId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getInventoryReimbursements + managerId + '/' + userId).pipe(catchError(this.handleError));
  }
  getShipmentsItem(managerId: string, userId: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getShipmentsItem + managerId + '/' + userId).pipe(catchError(this.handleError));
  }

  updateShipmentCase(userId,record_id,reset_case){
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.updateShipmentCase}${userId}?record_id=${record_id}&reset_case=${reset_case}`).pipe(catchError(this.handleError));
  }
  getOperationalUsers(store_id)
  {
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.brandOperationalUsers}${store_id}`).pipe(catchError(this.handleError));
  }

  getDashboardActualVsExpectedAmountKPI(store_id,service_integration_id)
  {
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.getDashboardActualVsEstAmountKPI}${store_id}?service_integration_id=${service_integration_id}`).pipe(catchError(this.handleError));
  }

  getDashboardPerformanceKPI(store_id,filters,service_integration_id)
  {
  const case_type_filter='&case_type='+filters.case_type;
  const date_type=filters.performance_type=='year'?'&year=true':'';
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.getDashboardPerformanceKPI}${store_id}?service_integration_id=${service_integration_id}${case_type_filter}${date_type}`).pipe(catchError(this.handleError));
  }

  getDashboardAggregatedReimburmentAmountKPI(store_id,service_integration_id)
  {
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.getDashboardAggregatedReimburmentAmoutKPI}${store_id}?service_integration_id=${service_integration_id}`).pipe(catchError(this.handleError));
  }


    getRepayIntegrations(store_id,service_id){
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.getRepayIntegrations}${store_id}?service_id=${service_id}`).pipe(catchError(this.handleError));
   }
   
   /* This function is used to update future reconcilation date*/
   updateReconciliationDate(userId, record_id, selected_date){
    return this.http.get<CommonResponse>(`${environment.baseUrl + serviveUrl.updateReconcilationDate}${userId}?record_id=${record_id}&selected_date=${selected_date}`).pipe(catchError(this.handleError));
  }

  /* this function is used to dismiss case*/
  dismissShipment(store_id,data)
  {
    return this.http.post<CommonResponse>(environment.baseUrl + serviveUrl.getdismissCase + store_id,data).pipe(catchError(this.handleError));
  }

  /* This function is used to update follow up date*/
  updatefollowUpDateData(store_id,data){
    return this.http.post<CommonResponse>(environment.baseUrl + serviveUrl.updatefollowUpDate + store_id,data).pipe(catchError(this.handleError));
  }

  /* This function is used to update field value*/
  updateFieldValueData(store_id,data){  
    return this.http.post<CommonResponse>(environment.baseUrl + serviveUrl.updateFieldValue + store_id,data).pipe(catchError(this.handleError));
  }
}
