import { BehaviorSubject } from "rxjs"
import { User } from "../models"
import {ERole} from "./role.enums"


enum EPrivilege {
    can_set_sales_target='set_sales_target'
}

class DataoneRoleAndPermissions {
    listener$ = new BehaviorSubject<User>(null)
    me$: any={};

    constructor() {
        
        const user = localStorage.getItem('user');
        
        if (user) this.setUser(JSON.parse(user));
    }

    setUser (u: User) {
        this.me$ = u;
        this.listener$.next(u);
    }
    get isCompanyAdmin(){
        
        return   this.me$.role.role === ERole.COMPANY_ADMIN;
    }

    canSetSalesTarget(store_id){
        return this.me$.privileges.some(p => p.privilege ==EPrivilege.can_set_sales_target && (p.store_id==store_id || this.me$.role.role === ERole.COMPANY_ADMIN));
    }
 

}

export const rap$ = new DataoneRoleAndPermissions();
