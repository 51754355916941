import { BehaviorSubject } from "rxjs"
import { User } from "../models"

enum ERole {
    SUPER_ADMIN = "super_admin",
    COMPANY_ADMIN = "company_admin",
    COMPANY_OPERATION = "company_operation",
    COMPANY_VIEW_ONLY = "company_view_only",
    STORE_ADMIN = "store_admin",
    STORE_OPERATION = "store_operation",
    STORE_VIEW_ONLY = "store_view_only",
}

enum EPrivilege {
    repay_module_access='repay_module',
    view_repay_shipments='view_repay_shipments',
    assign_shipment='assign_shipment',
    internal_user='internal_user'
}

class RepayRoleAndPermissions {
    listener$ = new BehaviorSubject<User>(null)
    me$: any={};

    constructor() {
        
        const user = localStorage.getItem('user');
        
        if (user) this.setUser(JSON.parse(user));
    }

    setUser (u: User) {
        this.me$ = u;
        this.listener$.next(u);
    }
    get canAccessRepayModule(){
        return this.me$.privileges.some(p => p.privilege ==EPrivilege.repay_module_access);
    }
    get canViewShipments(){
        
        return this.me$.privileges.some(p => p.privilege ==EPrivilege.view_repay_shipments);
    }
    get canAssignShipment(){
        
        return this.me$.privileges.some(p => p.privilege ==EPrivilege.assign_shipment);
    }
    get isInternal(){
        
        return this.me$.privileges.some(p => p.privilege ==EPrivilege.internal_user);
    }
    get isStoreOperator(){
        
        return   this.me$.role.role === ERole.STORE_OPERATION;
    }
    get isStoreAdmin(){
        
        return   this.me$.role.role === ERole.STORE_ADMIN;
    }
    get isSuperAdmin(){
        
        return   this.me$.role.role === ERole.SUPER_ADMIN;
    }
    get email(){
        
        return this.me$.email;
    }

}

export const rap$ = new RepayRoleAndPermissions();
