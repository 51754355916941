import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import * as FaqAction from "../actions/faq.action";
import { FaqService } from './../../shared/services/faq.service'
@Injectable()
export class FaqEffects {
    constructor(private actions$: Actions, private store: Store, private faqService: FaqService) { }

    loadFaqs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FaqAction.LoadAllFaqs),
            mergeMap(() => this.faqService.index()
            .pipe(
                    map((data: any): any => FaqAction.SaveAllFaqs({ payload: data }),
                    catchError(() => of({ type: 'Hello' }))
                    )
                )
            )
        )
    );
}