import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Service to notify local storage values
 */
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class CommonDataObservableService {
  private request = new Subject<any>();
  public requestObservable$ = this.request.asObservable();
  integrations = new BehaviorSubject([]);
  private response = new BehaviorSubject<any>(null);
  public responseObservable$ = this.response.asObservable();
  repay_id: string;
  brand_id: string;
  case_type: string;
  isfragment: boolean;
  constructor(private _httpClient: HttpClient) { }

  sendData(data: any): void {
    this.request.next(data);
  }

  responseData(data: any): void {
    this.response.next(data);
  }

 async  getServices() {
    
    try{
      let res:any=await  this._httpClient.get(environment.baseUrl + '/api/v1/common/getservices').toPromise();
      const { response_body }=res;
      this.integrations.next((response_body || []).filter(a => !a.is_company_app));
    }
    catch(ex)
    {
      console.log(ex);
    }
  }



}

