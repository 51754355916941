import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
    // //console.log("this.modals added",this.modals)
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.element.id !== id);
  }

  open(id: string , data:any) {
    // open modal specified by id
    // //console.log("this.modals",this.modals)
    this.closeAll()
    const modal = this.modals.find(x => x.element.id === id);
    if(modal){
      modal.open();
    }
  }

  close(id: string) {
    // close modal specified by id
    const modal = this.modals.find(x =>x.element.id === id);
    if(modal){
      modal.close();
    }
    
  }
  closeAll(){
    this.modals.forEach(modal => {
      modal.close();
    })
  }
}
