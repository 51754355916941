import {
    loadCompanyStoreReport,
    loadCompanyStoreReportSuccess,
    loadCompanyStoreReportFailure,
    SelectStore
    
} from './actions';
import {
   SetUser,
   GetUserProfile,
   SetUserPrivilege,
   UpdateUserProfile,
   UpdateUser,
   
} from './actions/user.action';
import { RootEffects, FaqEffects } from './effects';
import { companyReducer } from './reducers';
import { userReducer } from './reducers/user.reducer'
import { faqReducer } from './reducers/faq.reducer'
import { repayReducer } from './reducers/repay.reducer'
import { getCurrentStoreDateRange } from './selectors';
import { getUserProfile , getUser} from './selectors/user.selector';
import { getRepayCustomcase } from './selectors/repay.selector';
import { faqLoading, faqList } from './selectors';


export const fromRoot = {
    loadCompanyStoreReport,
    loadCompanyStoreReportSuccess,
    loadCompanyStoreReportFailure,
    SetUser,
    companyReducer,
    userReducer,
    faqReducer,
    repayReducer,
    RootEffects,
    FaqEffects,
    getCurrentStoreDateRange,
    GetUserProfile,
    getRepayCustomcase,
    SetUserPrivilege,
    UpdateUserProfile,
    UpdateUser,
    getUser,
    SelectStore,
    faqLoading,
    faqList
    // getStateError,
    // getStateSelectedData,
};


